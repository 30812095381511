<template>
  <div class="container-Politica">
        <!-- -->
      <section class="container-fluid">
        <section class="container">
          <div class="row">
            <div class="col"><br>
            <h1 style="color:#400041;" class="bold">Política de privacidade</h1><br>
            </div>
            </div>
        </section>

        <div class="row">

          <div class="col-sm-12 fundo-roxo quadro">
            <br>
            <div class="espacamento txt-white">
           
              <p>Este documento contém a Política de Privacidade de dados e  Informações dos usuários do site da Culinar Conteúdos e Formação em Culinária  Ltda., com sede em São Paulo, SP, na rua Américo Brasiliense, 1.490, 2º andar,  sala 24, Chácara Santo Antonio, São Paulo, SP, CEP 04715-002, inscrita no  CNPJ/MF sob nº 35.612.467/0001-77<strong></strong></p>
<p>A <strong>CULINAR </strong>se preocupa com a sua privacidade  e, por isso, entende a importância de você, enquanto titular dos seus dados  pessoais, ter transparência de como processamos os seus dados em virtude da sua  relação conosco e da sua navegação nos nossos sites e aplicativos.</p>
<p>Apresentamos  abaixo algumas informações que vão te ajudar a entender melhor a Política de  Privacidade da <strong>CULINAR</strong> é a proteção  das informações que dizem respeito à sua vida pessoal. O tema está previsto na  Constituição Federal, em legislações setoriais (como no Marco Civil da Internet  e seu decreto regulamentador e no Código de Defesa do Consumidor), e na Lei  13.709/2018, conhecida como Lei Geral de Proteção de Dados Pessoais (&ldquo;LGPD&rdquo;).</p>
<p><strong>- DADO:</strong>   pode ser dado pessoal ou dado pessoal sensível.<br>
  <strong>- DADO PESSOAL:</strong> é a informação que permite identificar  pessoas físicas (por exemplo, o seu nome, o número da sua identidade, o seu  CPF, o seu endereço, seus dados bancários, sua localização).<br>
  <strong>- DADO PESSOAL SENSÍVEL:</strong> é um dado pessoal que reflete aspectos  mais íntimos e precisa ser mais protegido. Por exemplo, dados sobre a sua  saúde, a sua opinião política e a sua convicção religiosa são dados pessoais  sensíveis.<br>
  <strong>- TITULAR DE DADOS:</strong> é o &ldquo;dono&rdquo; dos dados, ou seja, a pessoa  a quem os dados se referem. Você é titular dos seus dados pessoais e sensíveis.<br>
  <strong>- TRATAMENTO DE DADOS:</strong> é qualquer ação que possa ser efetuada  com os seus dados. Inclui, por exemplo, o acesso, a coleta, o processamento, o  armazenamento e a eliminação.<br>
  <strong>- ANONIMIZAÇÃO:</strong> é o procedimento técnico utilizado para  impedir que determinados dados sejam relacionados a determinada pessoa. As  informações anonimizadas não são consideradas dados pessoais.<br>
  <strong>- COOKIES:</strong> são arquivos ou informações que podem  ser armazenadas em seus dispositivos (computador ou celular) quando você visita  os websites ou utiliza os serviços on-line, contendo, em regra, o nome do site  que o originou, o seu tempo de visita ao site e uma numeração, que é gerada  aleatoriamente. Os COOKIES, basicamente, refletem o seu histórico de navegação  e interesses na internet.</p>
<p>&nbsp;</p>
<p><strong>1.     QUAIS DADOS SÃO TRATADOS?</strong></p>
<p>&nbsp;</p>
<p>No  âmbito das suas atividades, a Culinar trata dados pessoais das pessoas que  acessarem seu site, conforme segue:</p>
<ol>
  <li>fornecidos  pelo titular, em virtude da aceitação da Política de Privacidade, podendo ser  dados pessoais (exemplo: nome, data de nascimento, CPF, endereço, cidade, CEP,  e-mail);</li>


  <li>dados  coletados automaticamente em virtude da sua navegação nos sites e aplicativos  da Culinar (exemplo: endereço de IP ou o seu MobileID – identificação que cada  aparelho móvel possui); </li>
  <li>dados  coletados por terceiros (parceiros)</li>

  <li>dados  enviados a terceiros (exemplo: envio para fornecedores de desenvolvimento e  serviços de hospedagem e infraestrutura tecnológica do site).</li></ol>
<p>Quando você visita o&nbsp;site coletamos o seu endereço IP e as  informações padrão de acesso à web como o tipo do seu navegador e as páginas  que acessou em nosso site.</p>
<p>Caso você se  inscreva, as informações de contato serão coletadas e antes de permitir o uso  dos serviços, podemos exigir o fornecimento de informações adicionais que serão  usadas para sua correta identificação no âmbito interno.</p>

<p>Todas  as formas de coleta de dados dependem do seu consentimento.</p>
<p>&nbsp;</p>
<p><strong> 2.       </strong><strong>QUAL A FINALIDADE DE TRATARMOS OS SEUS  DADOS?</strong></p>
<p><strong>&nbsp;</strong></p>
<p>A  Culinar trata os seus dados para finalidades legítimas e específicas, como:</p>
 <ol><li>garantir  o acesso ao site e as funcionalidades contratadas por você, de acordo com o seu  perfil.</li>

  <li>prestar  serviços de conteúdos culinários.</li>
  <li>possibilitar  a elaboração de relatórios estatísticos, sem dados pessoais, que demonstram o  fluxo de visitantes ao site e posterior envio a eventuais anunciantes.</li>

  <li>estreitar  relacionamento com você, através de ações de relacionamento, bem como melhorar  a comunicação entre o site e os visitantes. </li>
  <li>controlar  o acesso dos visitantes, em caso de efetivação de assinatura.</li>

  <li>cumprir  obrigações impostas em lei ou por órgãos reguladores das atividades da Culinar  que podem, por exemplo, determinar prazos de guarda de documentos.</li>
  <li>exercer  o direito de defesa em processo administrativo, judicial ou arbitral.</li>

  <li>desenvolver  e aperfeiçoar os recursos e as funcionalidades dos sites, aplicativos, produtos  e serviços da Culinar, para proporcionar uma melhor experiência para você e  seus clientes;</li>
	<li>outras  finalidades, por meio da sua autorização expressa (consentimento), nos casos em  que a lei determinar.</li>

	</ol>
	
<p>A <strong>TARIMBA NA COZINHA</strong>&nbsp;não venderá ou alugará nenhuma das suas  informações pessoais para terceiros e somente compartilhará conforme cláusula  3ª abaixo.</p>
<p>&nbsp;</p>
<p><strong>3.     COM QUEM PODEMOS COMPARTILHAR OS SEUS  DADOS?</strong></p>
<p><strong>&nbsp;</strong></p>
<p>A<strong> CULINAR</strong> pode compartilhar os seus  dados em determinadas situações, sempre de modo transparente e alinhado com as  diretrizes contidas nesta Política. O compartilhamento dos seus dados poderá  ser feito com:</p>
<ol>
  <li>prestadores  de serviços, fornecedores de produtos e parceiros de negócio (tais como ArtpluG  Comunicação Digital Ltda., CCW Consultoria Web Ltda.) essenciais para as  atividades da CULINAR, que também possuem obrigação de garantir a proteção dos  seus dados pessoais;</li>



  <li>autoridades  e órgãos governamentais em função de obrigações impostas em leis ou  regulamentos;</li>

  <li>no  caso de transações e alterações societárias envolvendo a CULINAR nas quais os  envolvidos também possuem obrigação de garantir a proteção dos seus dados  pessoais;</li>



  <li>com anunciantes, porém, são apenas dados genéricos, estatísticos e agregados,  provenientes das interações dos usuários com os sites. </li>
</ol>
<p>&nbsp;</p>
<p><strong>4.     O QUE ACONTECE COM OS SEUS DADOS APÓS O  TÉRMINO DA SUA RELAÇÃO COM A CULINAR?</strong></p>
<p><strong>&nbsp;</strong></p>
<p>Atingida  a finalidade para o tratamento dos seus dados, eles serão eliminados das nossas  bases internas ou serão submetidos ao procedimento de anonimização.<br>
  No  entanto, caso a <strong>CULINAR</strong> ainda tenha  alguma justificativa para manutenção dos dados, eles serão retidos pelo prazo  necessário para o cumprimento de obrigação legal ou regulatória, para o  exercício de direito de defesa em processo judicial, administrativo ou  arbitral.</p>
<p>&nbsp;</p>
<p><strong>5. COOKIES</strong></p>
<p>&nbsp;</p>
<p>Quando  você acessa nosso site, nós, incluindo as empresas que contratamos para  acompanhar como nosso site é usado, podemos colocar pequenos arquivos de dados  chamados &ldquo;cookies&rdquo; no seu computador.</p>
<p>Enviamos  um &ldquo;cookie da sessão&rdquo; para o seu computador quando você entra em sua conta ou  usa os serviços do site. Esse tipo de cookie nos ajuda a reconhecê-lo se  visitar várias páginas em nosso site durante a mesma sessão, para que não seja  necessário solicitar a sua senha em todas as páginas. Depois que você sair ou  fechar o seu navegador, esse cookie irá expirar e deixará de ter efeito.</p>
<p>Também  usamos cookies permanentes para outras finalidades, como para exibir o seu  endereço de e-mail em nosso formulário de acesso, para que você não precise  digitar novamente o endereço de e-mail sempre que entrar em sua conta.</p>
<p>Ao  utilizar nossos serviços e canais, e dar aceite a esta Política de Privacidade,  você concorda com os COOKIES utilizados pela <strong>CULINAR,</strong> conforme aviso contido em nossos sites e aplicativos.</p>
<p>&nbsp;</p>
<p><strong>6. RECEBIMENTO DE INFORMAÇÃO E RECADASTRAMENTO</strong></p>
<p>&nbsp;</p>
<p>Há a possibilidade de nossos usuários receberem e-mail  informativo com conteúdo sobre o site, desde que tenham concordado prévia e  expressamente.</p>
<p>O usuário dos nossos serviços pode a qualquer momento deixar de  receber comunicações do nosso site. Para tal, basta clicar no link &lsquo;remover&rsquo; ou  &lsquo;unsubscribe&rsquo; contido no final de cada email.</p>
<p>O usuário pode também, a qualquer momento, solicitar que todos  seus dados sejam apagados do sistema.</p>
<p>&nbsp;</p>
<p><strong>7. COMO PROTEGEMOS OS DADOS PESSOAIS</strong></p>
<p>&nbsp;</p>
<p>Seus  dados pessoais estão seguros com a Culinar, que toma todas as medidas técnicas  necessárias para proteger seus dados, tais como:</p>
<ol>
  <li>controle restrito sobre o acesso aos dados, mediante a definição de responsabilidades  das pessoas que terão possibilidade de acessá-los;</li>



  <li>estabelecimento de mecanismos de autenticação de acesso aos dados;</li>

  <li>política  de senha, com padronização de senha forte e com a obrigação de trocas de senhas  periodicamente, impedindo a reutilização das senhas anteriores e bloqueio por tentativas sem sucesso;</li>



  <li>os ambientes onde tratamos seus dados são monitorados por meio de inventário  detalhado dos acessos aos registros de conexão e de acesso a aplicações;</li>

  <li>mecanismos  de descarte seguro dos dados, eliminando a possibilidade de restauração</li>

  <li>SSL. Camada de segurança que criptografa o dado entre o servidor e a pessoa (site  seguro – aparece com cadeado). Impede acesso a terceiros.</li>
</ol>
	
<p>&nbsp;</p>	
<p><strong>8.     QUAIS SÃO OS DIREITOS DOS TITULARES?</strong></p>
<p><strong>&nbsp;</strong></p>
<p>A  LGPD garante a você os seguintes direitos com relação aos seus dados:<br>
</p>
<ol>
  <li>confirmar  se há tratamento dos seus dados;</li>

  <li>ter  acesso aos dados que estão sendo tratados;</li>

  <li>corrigir  dados que estejam incompletos, inexatos ou desatualizados;</li>

  <li>anonimizar,  bloquear ou eliminar os dados que sejam desnecessários, excessivos ou tratados  em desconformidade com a finalidade proposta e com as leis aplicáveis;</li>

  <li>transferir,  pelo procedimento de portabilidade, os seus dados a outro fornecedor, se  aplicável;</li>

  <li>eliminar  os dados tratados, exceto nas situações de guarda legal e outras dispostas em  Lei;</li>

  <li>obter  informações sobre as entidades públicas ou privadas com as quais os seus dados  são compartilhados;</li>

  <li>obter  informações sobre a possibilidade de não fornecer o seu consentimento, quando  cabível essa modalidade, e as consequências, em caso de negativa;</li>
  <li>revogar  o consentimento dado;</li>
  <li>revisar  decisões tomadas unicamente com base em tratamento automatizado de dados que  afetem os seus interesses, respeitados os segredos comercial e industrial, como  os critérios de prevenção a fraudes.</li>
</ol>
<p> <br>
  No atendimento das suas solicitações a <strong>CULINAR</strong> sempre levará em consideração o  respeito à          legislação aplicável,  e na impossibilidade de atendimento dessa, as respostas serão justificadas.<br>
  A <strong>CULINAR </strong>entende que, na qualidade de titular dos seus dados pessoais, você deve ter  conhecimento e transparência sobre os seus dados e o modo como eles são  tratados e, por isso, disponibiliza os dados abaixo do encarregado da <strong>CULINAR </strong>(DPO) para proceder com solicitações  relacionadas aos seus dados.</p>
<p>Email: <a href="mailto:andre.victoria@tarimbanacozinha.com.br">andre.victoria@tarimbanacozinha.com.br</a></p>
<p>&nbsp;</p>
<p><strong>9.     A CULINAR TRATA DADOS FORA DO PAÍS?</strong></p>
<p><strong>&nbsp;</strong></p>
<p>A <strong>CULINAR</strong> é empresa com sede e  operação no Brasil, mas alguns tipos específicos de tratamento de dados  pessoais podem ser eventualmente realizados em outros países, quando necessário  para serviços específicos contratados pela <strong>CULINAR</strong>,  como armazenamento de dados em nuvem.<br>
  Mesmo  nestas situações, os prestadores de serviços contratados pela <strong>CULINAR</strong> estarão submetidos às  obrigações legais aplicáveis.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>10.     QUAL A LEI APLICÁVEL A ESTA POLÍTICA DE  PRIVACIDADE?</strong></p>
<p><strong>&nbsp;</strong></p>
<p>Esta  Política de Privacidade será interpretada de acordo com a lei brasileira  aplicável e qualquer questão decorrente desta Política deverá ser submetida  para julgamento no foro da Comarca da Capital de São Paulo.</p>
<p>&nbsp;</p>
<p><strong>11.     A QUEM ESSA POLÍTICA DE APLICA?</strong></p>
<p><strong>&nbsp;</strong></p>
<p>Esta  Política se aplica à Culinar Conteúdos e Formação  em Culinária Ltda, empresa inscrita no CNPJ sob o n. 35.612.467/0001-77, bem como a todas as sociedades  por ela controladas direta e indiretamente.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>12.     ESSA POLÍTICA PODE SER ALTERADA?</strong></p>
<p><strong>&nbsp;</strong></p>
<p>A <strong>CULINAR </strong>se reserva o direito de  atualizar e alterar esta Política a qualquer momento. Para ter conhecimento dos  termos atualizados, acesse os nossos sites.</p>

                
            </div>
                 


               
          </div><!-- col -->

         

        </div>
      </section>




      <!-- -->
  </div>
</template>

<style>

.espacamento{padding:0 40px;}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo{background:#400041;padding-bottom:20px;}
.fundo-roxo2{background:#764573;padding-bottom:20px;}
.quadro{min-height:300px;}
.txt-white{color:#FFF;}
</style>

<script>


export default {
  name: 'Politica',
  components: {},
  data(){
    return {
     
    }
  },
  methods:{
   
  },
  computed:{
    
  },
  
  mounted(){
     

  }
}
</script>